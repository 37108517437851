









































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, List, Stepper } from "vant";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [List.name]: List,
    [Stepper.name]: Stepper,
  },
})
export default class RemainStock extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {
        num: 1,
        remainStock: 1,
        isShow: true,
      };
    },
  })
  public stockInfo!: { num: number; remainStock: number; isShow: boolean }; //remainStock库存
  @Prop({
    type: Number,
    default: 0,
  })
  public pur!: number; //限制

  getMax(pur: number, remainStock: number): number {
    let num = 1;
    if (pur > 0) {
      if (pur > remainStock) {
        //当限制数量大于库存时
        num = remainStock;
      } else {
        num = pur;
      }
    } else {
      num = remainStock;
    }
    return num;
  }
}
