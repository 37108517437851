var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-pay"},[_c('van-nav-bar',{class:{ head: _vm.scrollTop < 50 },attrs:{"title":_vm.orderInfo.orderStatusName,"left-arrow":"","fixed":"","border":false,"placeholder":true},on:{"click-left":_vm.onClickLeft}}),(_vm.orderInfo.orderStatusEnum === 'PEND_PAYMENT')?_c('van-row',{staticClass:"p-time",attrs:{"type":"flex","align":"center","justify":"center"}},[_c('van-row',{attrs:{"type":"flex","align":"center"}},[_c('span',{staticClass:"p-t-title"},[_vm._v("支付倒计时：")]),_c('van-count-down',{staticStyle:{"font-size":"0.64rem","font-weight":"Medium"},attrs:{"time":Number(_vm.orderInfo.orderExpireTime),"format":"mm : ss "},on:{"finish":_vm.finish}})],1)],1):_vm._e(),_c('payment-head',{attrs:{"is-order":true,"head-img":_vm.orderInfo.commodityPicture,"commodity-title":_vm.orderInfo.commodityTitle,"specs":_vm.orderInfo.specs,"sub-specs":_vm.orderInfo.subSpecs,"num":_vm.orderInfo.num,"pay-info-list":_vm.orderInfo.payInfoList},on:{"goMallDetail":function($event){return _vm.goMallDetail(_vm.orderInfo.commodityInfoId)}}}),(_vm.orderInfo.deliveryWayEnum !== 'VIRTUAL')?_c('distribution-way',{attrs:{"is-mall":true,"delivery-way":_vm.orderInfo.deliveryWayEnum,"express-name":_vm.orderInfo.expressName,"express-number":_vm.orderInfo.expressNumber,"delivery-way-explain":_vm.orderInfo.deliveryWayExplain}}):_vm._e(),(_vm.orderInfo.deliveryWayEnum !== 'EXPRESS')?[(
        _vm.orderInfo.exchangeCode &&
        (_vm.orderInfo.orderStatusEnum === 'WAIT_GET_TICKET' ||
          _vm.orderInfo.orderStatusEnum === 'ORDER_COMPLATE' ||
          _vm.orderInfo.orderStatusEnum === 'WAITING_FOR_RECEIVED')
      )?[_c('conversion-code',{attrs:{"order-status":_vm.orderInfo.orderStatusEnum,"delivery-way-enum":_vm.orderInfo.deliveryWayEnum,"third-part-flag":_vm.orderInfo.thirdPartFlag,"exchange-code":_vm.orderInfo.exchangeCode.split(',')}}),_c('pay-line')]:_vm._e(),(
        _vm.orderInfo.orderStatusEnum === 'ORDER_COMPLATE' &&
        _vm.orderInfo.thirdPartFlag === '1' &&
        _vm.orderInfo.exchangeUrl
      )?_c('conversion-address',{attrs:{"exchange-url":_vm.orderInfo.exchangeUrl}}):_vm._e(),(
        _vm.orderInfo.exchangeNotice && _vm.orderInfo.deliveryWayEnum === 'VIRTUAL'
      )?_c('convertible-notes',{attrs:{"exchange-notice":_vm.orderInfo.exchangeNotice}}):_vm._e()]:_vm._e(),(_vm.orderInfo.deliveryWayEnum === 'EXPRESS')?[_c('goods-info',{attrs:{"is-border":false,"addr-name":_vm.orderInfo.consigneeName,"addr-mobile":_vm.orderInfo.consigneePhone,"receiving-address":_vm.orderInfo.receivingAddress}}),_c('pay-line')]:_vm._e(),(_vm.orderInfo.payInfoList)?_c('order-details',{attrs:{"is-order":true,"info":_vm.orderInfo.payInfoList,"is-mall":true,"order-status":_vm.orderInfo.orderStatusEnum,"order-freight-amt":_vm.orderInfo.orderFreightAmt,"all-price":_vm.orderInfo.actuallyPaidAmt}}):_vm._e(),_c('order-info',{attrs:{"order-code":_vm.orderInfo.salesOrderCode,"order-time":_vm.orderInfo.orderCreateTime,"pay-time":Number(_vm.orderInfo.payTime),"pay-way":_vm.orderInfo.payWayCode,"pay-remark":_vm.orderInfo.remark,"order-status":_vm.orderInfo.orderStatusEnum}}),(_vm.orderInfo.exchangeNotice && _vm.orderInfo.deliveryWayEnum !== 'VIRTUAL')?_c('convertible-notes',{attrs:{"exchange-notice":_vm.orderInfo.exchangeNotice}}):_vm._e(),(_vm.orderInfo.returnGoodList && _vm.orderInfo.returnGoodList.length > 0)?_c('per-steps',{attrs:{"steps":_vm.orderInfo.returnGoodList.length,"set-data":_vm.orderInfo.returnGoodList}}):_vm._e(),(_vm.orderInfo.customerPhone)?_c('mall-telephone',{attrs:{"contact-number":_vm.orderInfo.customerPhone}}):_vm._e(),(_vm.orderInfo.orderStatusEnum === 'PEND_PAYMENT')?_c('pay-btn',{attrs:{"order-status":_vm.orderInfo.orderStatusEnum},on:{"payCancel":_vm.payCancel,"payConfirm":_vm.payConfirm}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }