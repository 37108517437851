



















































































































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image, Popup, Dialog, Button } from "vant";
import Contact from "@/views/Mall/Payment/Contact.vue";
import PaymentHead from "@/views/Mall/Payment/PaymentHead.vue";
import PickupWay from "@/views/Mall/Payment/PickupWay.vue";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
import PayLine from "@/views/Mall/Payment/Line.vue";
import OrderDetails from "@/views/Mall/Payment/OrderDetails.vue";
import ShippingAddress from "@/views/Mall/Payment/ShippingAddress.vue";
import MyAddressList from "@/views/Mine/MoreServices/Address/MyAddressList.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    Contact,
    PaymentHead,
    PickupWay,
    ItemTitle,
    PayLine,
    OrderDetails,
    ShippingAddress,
    MyAddressList,
  },
})
export default class ConfirmOrder extends Mixins(Mixin) {
  postCost = 0; //邮费
  postFree = false; //是否免邮费
  showPrivacy = false; //条款弹窗
  scrollTop = 0;
  pickup = ""; //判断取货方式
  value = ""; //备注
  check = false; //是否点击条款
  advanceInfo: order.GoodAdvanceRes = {}; //预订单信息
  AddrList: member.AddrWithPriceRes = { newAddrDistributionScope: [] }; //地址列表
  defaultAddress: member.NewAddrRes = {}; //默认地址
  activityOrderId = "";
  priceInfo: Array<order.PayInfo> = []; //价格
  showAddresses = false; //可配送弹窗
  isLoading = false;
  isOneFreight = false; //判断是否有现金的情况
  isExchange = false; //是否是兑换奖品
  mounted(): void {
    this.getAdvanceOrder();
    this.activityOrderId = this.$route.query.activityOrderId
      ? String(this.$route.query.activityOrderId)
      : "";
    if (this.$route.query.exchange) {
      this.isExchange = true; //是兑换商品
    } else {
      this.isExchange = false; //不是兑换商品
    }
    window.addEventListener("scroll", this.handleScroll);
    // 更新一下字典地址信息
    this.InterfaceCacheModule.getData({
      cacheKey: "region.getRegionList",
      apiMethod: this.$api.dictionaryApi.region.getRegionList,
      callback: ({ data }) => {
        this.DictionaryModule.SET_addresses_PERSIST(data);
      },
    });
  }

  beforeDestroy(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }
  //页面滚动监听
  handleScroll(): void {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
  }
  //返回
  onClickLeft(): void {
    this.$router.go(-1);
  }
  /*
   * 字体状态 三种字体大小自适应容器宽度的方法
   */
  sizeState(): void {
    let l = document
      .getElementsByClassName("b-l-name")[0]
      .getBoundingClientRect().width;
    let r = document
      .getElementsByClassName("btn-right")[0]
      .getBoundingClientRect().width;
    let w =
      Math.ceil(document.body.getBoundingClientRect().width) -
      r -
      Math.ceil(r / 4) -
      l;
    let dom = document.getElementsByClassName("l-price")[0] as HTMLElement;
    let pWidth = dom.getBoundingClientRect().width;

    let scale = w / pWidth;

    if (dom && dom.style) {
      if (this.priceInfo.length > 2) {
        dom.style.fontSize = scale * 100 + "%";
      } else {
        dom.style.fontSize = "";
      }
    }
  }

  /*
   * 取货方式
   */
  onChoose(id: string): void {
    this.pickup = id;
    this.getFreightAmt();
  }

  /*
   * 打开选择地址组件时
   */
  openAddresses(): void {
    this.$nextTick(() => {
      (this.$refs.address as MyAddressList).refreshData();
    });
  }

  /*
   * 计算运费
   */
  getFreightAmt(): number {
    let price = 0;
    if (this.pickup == "02") {
      //快递取票
      let money = this.advanceInfo.payInfoList?.find((item) => {
        return item.key === "现金";
      }) || { price: 0 };
      if (
        this.AddrList.freeShipping &&
        Number(money.price) >= this.AddrList.fullMoney!
      ) {
        price = 0;
        this.postFree = false;
      } else if (
        (this.defaultAddress && this.defaultAddress.freeShipping) ||
        (this.defaultAddress &&
          this.defaultAddress.fullAmountFreeShipping &&
          Number(money.price) >= this.defaultAddress.fullAmount!)
      ) {
        //一定不包邮 ，不用再判断是否满足 包邮金额，直接去遍历 选中地址里的运费
        price = 0;
        this.postFree = false;
      } else {
        price =
          this.defaultAddress && this.defaultAddress.logisticsPrice
            ? this.defaultAddress.logisticsPrice
            : 0;
        this.postFree = false;
      }
    } else {
      this.postFree = false;
      //不选择快递取票
      price = 0;
    }
    this.postCost = price;
    return price;
  }
  //点击条款小圆圈
  onCheck(): void {
    this.check = !this.check;
  }
  //处理金额
  dealPrice(item: order.PayInfo, val?: number): string {
    let price = "";
    if (item.key === "现金") {
      let one = item.price ? item.price : "";
      let two = val || "";
      if (this.pickup === "02") {
        price = (Number(one) + Number(two)).toString();
      } else {
        price = one;
      }
      if (!price.includes(".")) {
        price = price + ".00";
      }
    } else {
      price = item.price || "";
    }

    return price;
  }
  /*
   * 查看购票协议
   */
  shopTerms(): void {
    let name = "《购物服务条款》";
    let type = "SHOPPING_CLAUSE"; //购物服务条款的type传这个
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  //条款弹窗
  agreePrivacy(): void {
    this.check = true;
    this.showPrivacy = false;
  }
  /*
   * 提交订单
   */
  onConfirm(): void {
    if (this.advanceInfo.pickUpType) {
      //当不是虚拟商品时，要选择配送方式
      if (!(this.pickup == "01" || this.pickup == "02")) {
        this.$toast("请选择配送方式");
        return;
      }
    }

    if (this.pickup === "02") {
      if (this.defaultAddress) {
        if (this.defaultAddress.isSupport === false) {
          this.$toast("请重新选择地址");
          return;
        }
      } else {
        this.$toast("请选择地址");
        return;
      }
    }
    if (!this.check) {
      // this.showPrivacy = true;
      this.$toast("请阅读并同意购物服务条款");
      return;
    }
    //是否在微博环境
    if (this.getIsWeiBo()) {
      return;
    }

    this.isLoading = true; //防止网络不稳定多次提交
    let addressId = this.defaultAddress ? this.defaultAddress.id : "";
    let data = {
      activityOrderId: this.activityOrderId,
      buyRemark: this.value,
      consigneeId: this.pickup == "01" ? "" : addressId,
      deliveryWay: this.pickup,
      uuid: this.$route.query.uuid.toString(),
    };
    this.$api.orderApi.goodOrder.createGoodOrder(
      data,
      ({ data }) => {
        this.isLoading = false;
        let str = {
          orderId: `${data.orderId!}`,
          orderType: "COMMODITY",
        };
        if (data.actuallyPaidAmt && data.actuallyPaidAmt > 0) {
          //去支付页面
          this.$router.replace({
            path: "/payconfirm",
            query: { ...str },
          });
        } else {
          //去支付成功页面
          this.$router.replace({
            path: "/paysuccess/good",
            query: { status: "TRADE_SUCCESS", ...str },
          });
        }
      },
      (error) => {
        this.isLoading = false;
        error && this.$toast(error);
      }
    );
  }
  /*
   * 获取地址列表
   */
  getAddressList(commodityInfoId?: number): void {
    let params = {
      commodityInfoId: Number(commodityInfoId),
      type: "2",
      uuid: this.$route.query.uuid.toString(),
    };
    this.$api.memberApi.address.getAddressWithPrice(params, ({ data }) => {
      if (data) {
        this.AddrList = data!;
        this.defaultAddress = data.newAddrList
          ? data.newAddrList[0]
          : this.defaultAddress;
      }
    });
  }
  /*
   * 获取修改后的地址
   */
  setAddress(item: member.MyAddrRes): void {
    this.defaultAddress = item;
    this.getFreightAmt();
    this.showAddresses = false;
  }
  /*
   * 关闭选座地址
   */
  addressClose(newAddrList: Array<member.NewAddrRes>): void {
    this.showAddresses = false;
    if (newAddrList.length <= 0) {
      //当组件地址列表为空时，展示的默认地址也为空
      this.defaultAddress = {};
    }
  }
  /*
   * 获取预订单
   */
  getAdvanceOrder(): void {
    this.$api.orderApi.goodOrder.getGoodAdvance(
      this.$route.query.uuid.toString(),
      ({ data, state }) => {
        if (state !== "SUCCESS") {
          let timer = setTimeout(() => {
            this.onClickLeft();
            clearTimeout(timer);
          });
          return;
        }
        if (data.pickUpType) {
          if (data.pickUpType.includes(",")) {
            this.pickup = data.pickUpType.split(",")[0];
          } else {
            this.pickup = data.pickUpType;
          }
        }
        this.advanceInfo = data;
        let arr = new Array(3);
        data.payInfoList?.forEach((item) => {
          if (item.key === "积分") {
            arr[0] = item;
          }
          if (item.key === "印花") {
            arr[1] = item;
          }
          if (item.key === "现金") {
            arr[2] = item;
          }
        });
        data.payInfoList = arr.filter((item) => item !== undefined);
        this.priceInfo = data.payInfoList || [];
        if (this.priceInfo) {
          this.isOneFreight = this.priceInfo.some((item) => {
            return item.key === "现金";
          });
        }
        this.sizeState();
        this.getAddressList(data.commodityInfoId);
      }
    );
  }
}
