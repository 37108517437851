












































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class DistributionWay extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  public deliveryWay!: string; //配送方式
  @Prop({
    type: String,
    default: "",
  })
  public expressName!: string; //配送名称
  @Prop({
    type: String,
    default: "",
  })
  public expressNumber!: string; //配送单号

  @Prop({
    type: String,
    default: "",
  })
  public deliveryWayExplain!: string; //取票地址

  @Prop({
    type: Boolean,
    default: false,
  })
  public isMall!: boolean; //是否是商城订单
  @Prop({
    type: String,
    default: "",
  })
  public productSourceEnum!: string; //订单来源

  copyShaneUrl(): void {
    var input = document.createElement("input"); // 直接构建input
    input.value = this.expressNumber; // 设置内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    if (document.execCommand("Copy")) {
      this.$toast("复制成功");
    } else {
      this.$toast("复制失败");
    }
    document.body.removeChild(input); // 删除临时实例
  }
  wayName(type: string): string {
    let name = "";
    if (type === "ETICKET") {
      name = "电子票";
    } else if (type === "SCENE") {
      if (this.isMall) {
        name = "现场取货";
      } else {
        name = "自助取票";
      }
    } else if (type === "VIRTUAL") {
      name = "虚拟产品";
    } else if (type === "SHOP") {
      name = this.productSourceEnum !== "MY" ? "门店自取" : "上门自取";
    } else if (type === "SHOW_UP") {
      name = "临场派票";
    } else if (type === "CARD") {
      name = "身份证入场";
    } else if (type === "WINDOW") {
      name = "窗口出票";
    }
    return name;
  }
}
