


















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image, Icon } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
    ItemTitle,
  },
})
export default class ShippingAddress extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public defaultAddress!: member.NewAddrRes;
  chooseAddress(): void {
    this.$emit("chooseAddress", true);
  }
}
