































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Swipe, SwipeItem, Image } from "vant";
@Component({
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
  },
})
export default class MallSwiper extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public images!: cms.TheaterAdvertDto[];
  goDetail(item: cms.TheaterAdvertDto): void {
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
  }
}
