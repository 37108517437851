





























































































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, CountDown, Dialog, Toast } from "vant";
import PaymentHead from "@/views/Mall/Payment/PaymentHead.vue";
import DistributionWay from "@/views/Mall/Payment/DistributionWay.vue";
import PayLine from "@/views/Mall/Payment/Line.vue";
import OrderDetails from "@/views/Mall/Payment/OrderDetails.vue";
import OrderInfo from "@/views/Mall/Payment/OrderInfo.vue";
import InvoiceInfo from "@/views/Mall/Payment/InvoiceInfo.vue";
import GoodsInfo from "@/views/Mall/Payment/GoodsInfo.vue";
import PayBtn from "@/views/Mall/Payment/PayBtn.vue";
import ConvertibleNotes from "@/views/Mall/Payment/ConvertibleNotes.vue";
import MallTelephone from "@/views/Mall/Payment/Telephone.vue";
import ConversionCode from "@/views/Mall/Payment/ConversionCode.vue";
import ConversionAddress from "@/views/Mall/Payment/ConversionAddress.vue";
import PerSteps from "@/views/TicketOrder/PerOrderDetails/components/PerSteps.vue";
import { Route, NavigationGuardNext } from "vue-router";
import EventBus from "@/utils/eventBus";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [CountDown.name]: CountDown,
    PaymentHead,
    DistributionWay,
    PayLine,
    OrderDetails,
    OrderInfo,
    InvoiceInfo,
    GoodsInfo,
    PayBtn,
    ConvertibleNotes,
    MallTelephone,
    ConversionCode,
    ConversionAddress,
    PerSteps,
  },
})
export default class MallOrder extends Mixins(Mixin) {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      (vm as MallOrder).popFullPath = from.fullPath;
    });
  }
  scrollTop = 0;
  orderId = "";
  orderInfo: order.EsOrderDetailGoodInfo = {}; //订单信息
  peopleInfo = {
    //用户信息
    name: "",
    num: "",
  };
  popFullPath = ""; //判断那个页面跳转过来
  emitRefreshOrderList = false;

  mounted(): void {
    window.addEventListener("scroll", this.handleScroll);
    this.orderId = this.$route.params.orderId || "";
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.getDateInfo();
  }

  beforeDestroy(): void {
    if (this.emitRefreshOrderList) {
      EventBus.$emit("refreshActiveOrderList");
    }
    window.removeEventListener("scroll", this.handleScroll);
  }
  //页面滚动事件
  handleScroll(): void {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
  }
  //返回
  onClickLeft(): void {
    //this.$router.go(-1);
    if (!this.$route.query.status) {
      this.emitRefreshOrderList = true;
    }
    let url = this.$route.query.status
      ? `/personal-data/order?status=${this.$route.query.status}`
      : `/personal-data/order?status=ALL`;
    //
    if (this.$route.query.source) {
      this.$router.go(-1);
    } else {
      this.$router.push({
        path: url,
      });
    }
  }
  /*
   * 去商品详情
   */
  goMallDetail(id?: string): void {
    this.$router.push(`/mall/details?commodityId=${id}`);
  }
  /*
   * 是否有现金
   */
  hascash(payInfoList?: Array<order.PayInfo>): boolean {
    let show = false;
    if (payInfoList) {
      show = payInfoList.some((item) => {
        return item.key === "现金";
      });
    }
    return show;
  }
  /*
   * 获取详情
   */
  getDateInfo(): void {
    this.$api.orderApi.goodOrder.getOrderDetailGood(
      this.orderId,
      ({ data }) => {
        Toast.clear();
        let arr = new Array(3);
        data.payInfoList?.forEach((item) => {
          if (item.key === "积分") {
            arr[0] = item;
          }
          if (item.key === "印花") {
            arr[1] = item;
          }
          if (item.key === "现金") {
            arr[2] = item;
          }
        });
        data.payInfoList = arr.filter((item) => item !== undefined);
        this.orderInfo = data;
        this.peopleInfo.name = data.contactsName!;
        this.peopleInfo.num = data.contactsPhone!;
      },
      (error) => {
        Toast.clear();
        error && this.$toast(error);
      }
    );
  }
  /*
   *取消订单
   */
  payCancel(): void {
    Dialog.confirm({
      message: "您确定取消此订单吗？",
    })
      .then(() => {
        monitorEvent("OrderDetail_ClickCancel", "点击取消订单"); // 埋点：订单详情，点击取消订单
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
        });
        this.$api.orderApi.goodOrder.cancelOrder(
          this.orderId,
          (data) => {
            if (data.data === true) {
              this.$toast("取消成功");
              this.emitRefreshOrderList = true;

              this.$router.push({
                path: `/personal-data/order`,
                query: { status: "ALL" },
              });
            }
          },
          (error) => {
            error && this.$toast(error);
          }
        );
      })
      .catch(() => {
        // on cancel
      });
  }

  /*
   * 立即付款
   */
  payConfirm(): void {
    monitorEvent("OrderDetail_ClickOrder", "点击立即付款"); // 埋点：订单详情，点击立即付款
    // 待付款
    // TODO:需要处理的地方(待定，不确定有没有商城)
    this.$router.push({
      path: "/payconfirm",
      query: { orderId: this.orderId, orderType: "COMMODITY" },
    });
  }

  /*
   *倒计时结束
   */
  finish(): void {
    this.emitRefreshOrderList = true; //倒计时结束 刷新订单列表
    this.getDateInfo();
  }
}
