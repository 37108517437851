

























































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Swipe, SwipeItem, Cell, CellGroup, Image, Row, Icon } from "vant";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
import { monitorEvent } from "@/utils/youMengMonitor";
@Component({
  components: {
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [Row.name]: Row,
    [Icon.name]: Icon,
  },
})
export default class Vip extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: [],
  })
  vipList!: good.CommodityDto[];
  isSwiper = true;
  mounted(): void {
    this.initSwiper();
  }
  initSwiper(): void {
    new Swiper(".swiper1", {
      slidesPerView: "auto",
      spaceBetween: 10,
      slidesOffsetAfter: 30,
      autoplay: false,
      loop: false,
      on: {
        // click: function (swiper: any, event: any) {
        //   console.log(77, vm.num, 88, event);
        // },
      },
    });
  }
  /*
   * 去会员专享
   */
  goExclusive(): void {
    this.$router.push(`/mall/vip-exclusive`);
  }
  goDetails(id: number | undefined): void {
    if (this.$route.name === "mall") {
      //埋点：商城首页，点击会员专享商品
      monitorEvent("Shop_ClickVIPGood", "点击会员专享商品");
    }
    this.$router.push(`/mall/details?commodityId=${id}`);
  }
}
