































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Image } from "vant";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class MallIntegral extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public memberInfo!: member.MemberInfo;
  // @Prop({
  //   type: Boolean,
  //   default: false,
  // })
  // public isLogin!: boolean;
  goLogin(): void {
    this.$emit("goLogin");
  }
  dealIntegral(integral: number): string {
    let num = "";
    if (integral < 10000) {
      num = integral.toString();
    } else if (10000 <= integral && integral < 10000000) {
      let number = integral / 10000;
      num = number.toFixed(1) + "万";
    } else if (10000000 <= integral) {
      let number = integral / 10000000;
      num = number + "万";
    }
    return num;
  }
}
