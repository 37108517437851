































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class OrderInfo extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public orderCode!: string; //订单编号
  @Prop({
    type: Number,
    default: null,
  })
  public orderTime!: number; //订单时间
  @Prop({
    type: Number,
    default: null,
  })
  public payTime!: number; //支付时间
  @Prop({
    type: String,
    default: "",
  })
  public payWay!: string; //支付方式
  @Prop({
    type: String,
    default: "",
  })
  public payRemark!: string; //备注
  @Prop({
    type: String,
    default: "",
  })
  public orderStatus!: string; //备注
  @Prop({
    type: String,
    default: "",
  })
  public productSourceEnum!: string; //订单来源
  timeFilter(time?: number): string {
    if (!time) {
      return "";
    }
    let date = new Date(time);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let month: number | string = "";
    let days: number | string = "";
    let hours: number | string = "";
    month = m < 10 ? "0" + m : m;
    let d = date.getDate();
    days = d < 10 ? "0" + d : d;
    let h = date.getHours();
    hours = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let min = minute < 10 ? "0" + minute : minute;
    let s = second < 10 ? "0" + second : second;
    return y + "." + month + "." + days + " " + hours + ":" + min + ":" + s;
  }
}
