














import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar } from "vant";
import VipMain from "./VipMain.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    VipMain,
  },
})
export default class VipExclusive extends Mixins(Mixin) {
  onClickLeft(): void {
    this.$router.go(-1);
  }
}
