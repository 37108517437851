












































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Tab,
  Tabs,
  Image,
  Row,
  Swipe,
  SwipeItem,
  NavBar,
  List,
  Toast,
} from "vant";
import Vip from "@/views/Mall/MallPage/Vip.vue";
import WaterfallLayout from "@/views/Mall/Details/waterfallLayout.vue";
import FooterLine from "@/views/Mall/MallPage/FooterLine.vue";
import MallSwiper from "@/views/Mall/MallPage/MallSwiper.vue";
import MallIntegral from "@/views/Mall/MallPage/MallIntegral.vue";
import { Route, NavigationGuardNext } from "vue-router";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  name: "mall",
  components: {
    [Swipe.name]: Swipe,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Image.name]: Image,
    [SwipeItem.name]: SwipeItem,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
    [List.name]: List,
    Vip,
    WaterfallLayout,
    FooterLine,
    MallSwiper,
    MallIntegral,
  },
})
export default class Mall extends Mixins(Mixin) {
  // 判断是否是返回，不是就重新加载页面，是就是看缓存数据
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      (vm as Mall).refreshOther();
      let isBackFrom = (vm as Mall).isBackFrom;
      if (isBackFrom) {
        (vm as Mall).isBackFrom = "";
      }
      if (!isBackFrom || isBackFrom != from.fullPath) {
        /**
         * isBackFrom 为空说明不是从 Home 出去的或者不需要保持位置的
         * isBackFrom != from.fullPath 表示是从 Home 出去的，但是不是从出去的第一个页面回来的
         */
        (vm as Mall).refreshPage(); // 刷新分页数据
      }
    });
  }

  isBackFrom = "";
  loading = false;
  finished = false;
  scrollTop = 0;
  images: cms.TheaterAdvertDto[] = []; //商城banner列表
  activeName = 0;
  navHeight = 0;
  height = 0;
  pages: number | undefined = 1; //总页数
  page = 0;
  size = 10;
  memberInfo: member.MemberInfo = {}; // 用户积分信息
  vipList: Array<good.CommodityDto> = []; //会员专享列表
  typeId: number | null = null;
  categoryList: dictionary.CommodityTypeDto[] = []; //商品分类
  classifyList: Array<good.CommodityDto> = []; //分类详情
  isFixed = false;
  memberLevelId = "0"; //会员id

  activated(): void {
    this.height = (this.$refs.nav as any).height;
    this.navHeight = this.height * 0.01 + 3.16;
    window.addEventListener("scroll", this.handleScroll);
  }

  deactivated(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }
  //去商品详情
  goMallDetails(itemInfo: good.CommodityDto): void {
    //埋点：商城，点击商品
    monitorEvent("Shop_ClickGood", "点击商品");
    let activityOrderId = this.$route.query.activityOrderId
      ? `&activityOrderId=${this.$route.query.activityOrderId}`
      : "";
    let path = `/mall/details?commodityId=${itemInfo.commodityInfoId}${activityOrderId}`;
    this.isBackFrom = path;
    this.$router.push(path);
  }
  //数据重置
  refreshPage(): void {
    // setTimeout(() => {
    //   if (this.$route.query.linkId) {
    //     this.activeName = Number(this.$route.query.linkId);
    //   } else {
    //     this.activeName = 0;
    //   }
    // }, 500);
    this.loading = false;
    this.finished = false;
    this.typeId = null;
    this.classifyList = [];
    this.scrollTop = 0;
    this.page = 0;
    this.$nextTick(() => {
      this.isFixed = false;
    });
    if (this.UserModule.isLogin) {
      this.getMallMember();
    } else {
      if (this.activeName === 0) this.typeId = null;
      this.classifyDetails();
    }
  }

  /*
   * 刷新除了分页以外的数据
   */
  refreshOther(): void {
    this.getMallBanner();
    this.getVipExclusive();
    this.getMallClassify();
  }

  //页面滚动
  handleScroll(): void {
    this.scrollTop =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
    let num = 46;
    if (this.images.length > 0) {
      num = 400;
    }
    if (this.scrollTop >= num) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  /*
   * 去搜索页
   */
  goSearch(): void {
    //埋点：商城，点击搜索按钮
    monitorEvent("Shop_ClickSearch", "点击搜索按钮");
    this.$router.push("/search/mall");
  }

  //去订单列表
  goOrder(): void {
    //埋点：商城，点击订单按钮
    monitorEvent("Shop_ClickOrder", "点击订单按钮");
    this.$router.push({
      path: `/personal-data/order`,
      query: { status: "ALL", orderType: "ORDER_GOOD" },
    });
  }
  //登录
  goLogin(): void {
    this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
      router: this.$router,
      newPage: true,
      goBackAfterLogin: true,
    });
  }

  /*
   * 频道切换
   */
  changeTab(name: number): void {
    this.loading = false;
    this.finished = false;
    this.page = 0;
    //埋点：商城，点击商品分类
    monitorEvent("Shop_ClickGoodType", "点击商品分类");
    this.classifyList = [];
    if (name == 1) {
      this.typeId = null;
      this.exchangeMine();
    } else {
      this.typeId = name;
      if (name == 0) this.typeId = null;
      this.classifyDetails();
    }
    this.activeName = name;
  }

  /*
   * 商城banner
   */
  getMallBanner(): void {
    this.$api.cmsApi.advert.cityAdverts("MALL_BANNER", ({ data }) => {
      this.images = data;
    });
  }

  /*
   * 会员专享
   */
  getVipExclusive(): void {
    let data = {
      page: 1,
      size: 10,
    };
    this.$api.goodApi.commodity.findCommoditiesMember(data, ({ data }) => {
      if (data.records) {
        data.records.forEach((value: good.CommodityDto) => {
          let isMember = false;
          if (value.lowestMemberPrices) {
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId === this.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == this.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == "0";
                }
              );
            }
          }
        });
        this.vipList = data.records;
      }
    });
  }

  /*
   * 商品分类
   */
  getMallClassify(): void {
    this.$api.dictionaryApi.commodity.findCommodityType(({ data }) => {
      this.categoryList = data;
      setTimeout(() => {
        if (this.$route.query.linkId) {
          this.activeName = Number(this.$route.query.linkId);
          this.changeTab(this.activeName);
        } else {
          this.activeName = 0;
        }
      }, 500);
    });
  }

  /*
   * 频道详情
   */
  classifyDetails(): void {
    // if (this.loading) {
    //   return;
    // }
    this.page++;
    this.loading = true;
    let data = {
      typeId: this.typeId!,
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.commodity.searchCommodities(
      data,
      ({ data }) => {
        this.loading = false;
        this.pages = data.pages;
        data.records?.forEach((value: good.CommodityDto) => {
          let isMember = false;
          if (value.lowestMemberPrices) {
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId === this.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == this.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == "0";
                }
              );
            }
          }
        });
        this.classifyList = this.classifyList.concat(data.records!);
        // 数据全部加载完成
        if (this.page >= Number(this.pages)) {
          this.finished = true;
        }
      },
      (error) => {
        this.loading = false;
        error && Toast(error);
      }
    );
  }

  /*
   * 我能兑换
   */
  exchangeMine(): void {
    if (this.loading) {
      return;
    }
    this.page++;
    this.loading = true;
    let data = {
      typeId: this.typeId!,
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.commodity.searchCommodityExchanges(
      data,
      ({ data }) => {
        this.loading = false;
        this.pages = data.pages;
        data.records?.forEach((value: good.CommodityDto) => {
          let isMember = false;
          if (value.lowestMemberPrices) {
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId === this.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == this.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == "0";
                }
              );
            }
          }
        });
        this.classifyList = this.classifyList.concat(data.records!);
        // 数据全部加载完成
        if (this.page >= Number(this.pages)) {
          this.finished = true;
        }
      },
      (error) => {
        this.loading = false;
        error && Toast(error);
      }
    );
  }

  /**
   * 获取商城会员信息
   */
  getMallMember(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "cuUser.memberInfo",
      apiMethod: this.$api.memberApi.cuUser.memberInfo,
      callback: ({ data }) => {
        this.memberInfo = data;
        this.memberLevelId = data.memberLevelId
          ? data.memberLevelId
          : this.memberLevelId;
        this.classifyDetails();
      },
    });
  }

  //列表滚动条与底部距离小于 offset 时触发
  onLoad(): void {
    if (!this.classifyList.length) {
      this.loading = false;
      return;
    }
    if (this.activeName === 1) {
      this.exchangeMine();
    } else {
      this.classifyDetails();
    }
  }
}
