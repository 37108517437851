


































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "./ItemTitle.vue";
import QRCode from "qrcodejs2";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class ConversionCode extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  public thirdPartFlag!: string; //是否勾选第三方兑换码标识
  @Prop({
    type: String,
    default: "",
  })
  public deliveryWayEnum!: string; //自助区取票

  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public exchangeCode!: string; //二维码字符串
  @Prop({
    type: String,
    default: "",
  })
  public orderStatus!: string; //订单状态

  mounted() {
    this.creatQrCode();
  }
  // 生成二维码
  creatQrCode(): void {
    if (this.thirdPartFlag !== "1") {
      new QRCode(this.$refs.qrcode, {
        text: `${this.exchangeCode[0]}`, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    }
  }
  /*
   *复制功能
   */
  copyShaneUrl(value: string): void {
    var input = document.createElement("input"); // 直接构建input
    input.value = value; // 设置内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    if (document.execCommand("Copy")) {
      this.$toast("复制成功");
    } else {
      this.$toast("复制失败");
    }

    document.body.removeChild(input); // 删除临时实例
  }
}
